@tailwind base;
@tailwind components;
@tailwind utilities;

h1, h2, h3, h4, h5 {
  font-family:'Josefin Sans';
  font-synthesis: none;
}

.goodhub-content p {
  @apply text-sm text-gray-500;
}

.goodhub-content h1 {
  @apply text-gray-800 text-lg font-bold mb-2 mt-4;
}

@screen sm {
  .goodhub-content p {
    @apply text-base;
  }

  .goodhub-content h1 {
    @apply text-xl;
  }
}